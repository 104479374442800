<template>
  <v-dialog v-model="dialog" width="800px" scrollable persistent>
    <v-card>
      <v-card-title primary-title> Exportar para Excel </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="3">
            <app-text-field
              v-model="form.emitted_at_start"
              type="date"
              label="Data Emissão Início"
            />
          </v-col>
          <v-col cols="3">
            <app-text-field
              v-model="form.emitted_at_end"
              type="date"
              label="Data Emissão Fim"
            />
          </v-col>
          <v-col cols="3">
            <app-text-field
              v-model="form.entry_at_start"
              type="date"
              label="Data Entrada Início"
            />
          </v-col>
          <v-col cols="3">
            <app-text-field
              v-model="form.entry_at_end"
              type="date"
              label="Data Entrada Fim"
            />
          </v-col>
        </v-row>

        <v-row class="d-flex align-center">
          <v-col>
            <CompanySelect
              clearable
              v-model="form.company"
              @input="setCompanyId($event)"
              :itemValue="(val) => val.id"
            />
          </v-col>
          <v-col cols="6">
            <SupplierSearch
              v-model="form.supplier"
              @input="setSupplierId($event)"
            />
          </v-col>
          <v-col cols="3">
            <app-select
              v-model="form.stock_status"
              :items="stockOptions"
              label="Status Estoque"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-chip-group v-model="form.status" column multiple>
              <v-chip small filter :value="'purchased'" color="primary">
                Compra
              </v-chip>
              <v-chip small filter :value="'draft'"> Rascunho </v-chip>
              <v-chip small filter :value="'canceled'" color="error">
                Cancelado
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="dialog = false" text> Cancelar </v-btn>
        <v-btn color="primary" @click="handleExport()"> Exportar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";

export default {
  components: {
    SupplierSearch,
    CompanySelect,
  },

  data() {
    return {
      dialog: false,
      form: {},

      baseForm: {
        emitted_at_start: null,
        emitted_at_end: null,
        entry_at_start: null,
        entry_at_end: null,
        status: ["purchased", "draft"],
        stock_status: null,
        supplier_id: null,
        brand_id: null,
        category_id: null,
        company_id: null,
      },

      stockOptions: [
        { value: 1, text: "Estoque Lançando" },
        { value: 0, text: "Estoque Pendente" },
        { value: null, text: "Todos" },
      ],

      date: new Date().toISOString().slice(0, 10),
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setSupplierId(supplier) {
      this.form.supplier_id = supplier.id || null;
    },

    setCompanyId(company) {
      this.form.company_id = company.id || null;
    },

    handleExport() {
      this.$loading.start();
      this.$http
        .download("purchase/export", this.form)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Compras_${this.date}.xlsx`);
          document.body.appendChild(link);
          link.click();
          // this.dialog = false;
          this.$loading.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>