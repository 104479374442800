<template>
  <div>
    <v-row>
      <v-col cols="8" md="5">
        <app-text-search-field
          @filter="showFilter = !showFilter"
          @input="handleSearchInput($event)"
        />
      </v-col>
      <v-col class="text-right d-md-block pb-0">
        <v-btn
          large
          bottom
          color="primary"
          @click="newPurchaseOrder()"
          class="mr-5 text-capitalize"
          v-if="$acl.can('purchasesCreateEdit')"
        >
          Novo
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="3">
        <app-text-field
          v-model="searchParams.delivered_at_start"
          type="date"
          label="Data Recebimento Início"
          @input="select()"
        />
      </v-col>
      <v-col cols="6" md="3">
        <app-text-field
          v-model="searchParams.delivered_at_end"
          type="date"
          label="Data Recebimento Fim"
          @input="select()"
        />
      </v-col>
      <v-col md="3">
        <app-text-field
          v-model="searchParams.confirmed_at_start"
          type="date"
          label="Data Confirmação Início"
          @input="select()"
        />
      </v-col>
      <v-col md="3">
        <app-text-field
          v-model="searchParams.confirmed_at_end"
          type="date"
          label="Data Confirmação Fim"
          @input="select()"
        />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col cols="12" md="3">
        <app-text-field
          v-model="searchParams.product_name"
          label="Produto"
          @change="select()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <CompanySelect
          clearable
          @input="setCompanyId($event)"
          :itemValue="(val) => val.id"
        />
      </v-col>
      <v-col cols="12" md="3">
        <SupplierSearch @input="setSupplierId($event)" />
      </v-col>
      <v-col cols="12" md="3">
        <SalesmanSearch
          label="Comprador"
          :hidePosition="true"
          clearable
          @input="setCreateBy($event)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <v-chip-group
          @change="select()"
          v-model="searchParams.status"
          column
          multiple
        >
          <template v-for="status in statuses">
            <v-chip
              small
              filter
              :value="status.value"
              :color="status.color"
              :key="status.value"
            >
              {{ status.text }}
            </v-chip>
          </template>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total de Produtos
              <br />
              <b class="text-h4">
                {{ $format.decimal(purchase_orders_total) }}
              </b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          disable-sort
          dense
          :headers="headers"
          @click:row="editPurchase($event.id)"
          :items="purchase_orders.data"
          :items-per-page="purchase_orders.per_page"
        >
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip :company="item.company" />
          </template>

          <template v-slot:[`item.confirmed_at`]="{ item }">
            {{ $format.dateBr(item.confirmed_at) }}
          </template>
          <template v-slot:[`item.delivered_at`]="{ item }">
            {{ $format.dateBr(item.delivered_at) }}
          </template>

          <template v-slot:[`item.net_total`]="{ item }">
            {{ $format.decimal(item.net_total) }}
          </template>
          <template v-slot:[`item.purchase_total`]="{ item }">
            {{ $format.decimal(item.purchase_total) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small filter :color="statuses[item.status].color">
              {{ statuses[item.status].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon v-bind="attrs" v-on="on" color="primary">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="item.status == 'confirmed'"
                  @click.stop="updateStatus('in_route', item.id)"
                >
                  <v-list-item-action>
                    <v-icon>mdi-truck-delivery</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Em Rota </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="item.status == 'in_route'"
                  @click.stop="receivePurchaseOrder(item.id)"
                >
                  <v-list-item-action>
                    <v-icon>mdi-truck-check-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Recebido </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="canCancel(item.status)"
                  @click.stop="updateStatus('canceled', item.id)"
                >
                  <v-list-item-action>
                    <v-icon>mdi-cancel</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Cancelar </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click.stop="printPurchaseOrder(item.id)">
                  <v-list-item-action>
                    <v-icon>mdi-printer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Imprimir </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <app-pagination @click="select($event)" :data="purchase_orders" />
      </v-col>
    </v-row>
    <PurchaseExportForm ref="PurchaseExportForm" />
  </div>
</template>

<script>
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import PurchaseExportForm from "@/components/purchase/form/PurchaseExportForm.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";
import { format, startOfMonth, lastDayOfMonth } from "date-fns";
export default {
  components: {
    SupplierSearch,
    CompanySelect,
    CompanyChip,
    PurchaseExportForm,
    SalesmanSearch,
  },

  data() {
    return {
      purchase_orders: {},
      purchase_orders_total: 0,

      statuses: {
        draft: { text: "Rascunho", color: "", value: "draft" },
        confirmed: { text: "Confirmado", color: "primary", value: "confirmed" },
        in_route: { text: "Em Rota", color: "warning", value: "in_route" },
        delivered: { text: "Entregue", color: "success", value: "delivered" },
        canceled: { text: "Cancelado", color: "error", value: "canceled" },
      },

      headers: [
        { text: "Código", value: "code", width: "10%", align: "center" },

        { text: "Empresa", value: "company", width: "10%", align: "center" },
        {
          text: "Confirmação",
          value: "confirmed_at",
          align: "center",
          width: "1%",
        },
        {
          text: "Recebimento",
          value: "delivered_at",
          align: "center",
          width: "1%",
        },

        {
          text: "Fornecedor",
          value: "supplier.name",
          width: "50%",
          align: "center",
        },
        {
          text: "Total Prod	",
          value: "net_total",
          width: "10%",
          align: "center",
        },
        {
          text: "Total 	",
          value: "purchase_total",
          width: "10%",
          align: "center",
        },

        {
          text: "Status",
          value: "status",
          width: "1%",
          align: "center",
        },
        {
          text: "",
          value: "action",
          width: "1%",
          align: "right",
        },
      ],

      showFilter: null,

      searchParams: {
        delivered_at_start: null,
        delivered_at_end: null,
        confirmed_at_start: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        confirmed_at_end: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      },
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("purchase-order/purchase-order", this.searchParams)
        .then((response) => {
          this.$loading.finish();

          this.purchase_orders = response.purchase_orders;
          this.purchase_orders_total = response.purchase_orders_total;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    setSupplierId(supplier) {
      this.searchParams.supplier_id = supplier ? supplier.id : null;
      this.select();
    },

    setCompanyId(company_id) {
      this.searchParams.company_id = company_id;
      this.select();
    },

    editPurchase(purchase_id) {
      if (this.$acl.can("purchasesCreateEdit")) {
        if (!this.$app.isMobile) {
          const routeData = this.$router.resolve({
            name: "PurchaseOrderFormIndex",
            params: { id: purchase_id },
          });
          window.open(routeData.href, "_blank");
        } else {
          this.$router.push(`/pedidos/${purchase_id}`);
        }
      }
    },

    newPurchaseOrder() {
      if (!this.$app.isMobile) {
        window.open("/pedidos/novo", "_blank");
      } else {
        this.$router.push("/pedidos/novo");
      }
    },

    async updateStatus(status, purchase_order_id) {
      this.$loading.start();
      await this.$http
        .update(
          "purchase-order/purchase-order/update-status",
          purchase_order_id,
          {
            status: status,
          }
        )
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    canCancel(purchase_status) {
      return !(purchase_status == "canceled" || purchase_status == "draft");
    },

    handleExport() {
      this.$refs.PurchaseExportForm.open();
    },
    printPurchaseOrder(purchase_order_id) {
      this.$router.push(`/pedidos/imprimir/${purchase_order_id}`);
    },
    receivePurchaseOrder(purchase_order_id) {
      if (!this.$app.isMobile) {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderReceiveForm",
          params: { id: purchase_order_id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(`/pedidos/receber/${purchase_order_id}`);
      }
    },

    setCreateBy(create_by) {
      this.searchParams.create_by = create_by ? create_by.id : null;
      this.select();
    },
  },
};
</script>

<style>
</style>